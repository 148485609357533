<template>
  <div class="container-form-login">
    <div class="content-login text-center">
      <div class="center-horizontall">
        <div
          @click="home"
          class="content-image cursor-pointer"
        >
          <img
            src="@/assets/logos/logo-bg-white.svg"
            alt="proplat-image.png"
          >
        </div>
      </div>
      <p class="text-title mon-bold mt-5">{{ texts.login.textTitle }}</p>
      <div class="mt-9">
        <v-text-field
          v-model="email"
          type="email"
          name="email"
          :label="texts.login.inputEmail.label"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular"
          :placeholder="texts.login.inputEmail.placeholder"
          persistent-placeholder
          @keypress="preLogin"
        >
        </v-text-field>
        <v-text-field
          v-model="password"
          name="password"
          :label="texts.login.inputPassword.label"
          :append-icon="bShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (bShowPassword = !bShowPassword)"
          :type="bShowPassword ? 'text' : 'password'"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mt-5"
          :placeholder="texts.login.inputPassword.placeholder"
          persistent-placeholder
          @keypress="preLogin"
        >
        </v-text-field>

      </div>
      <div class="center-horizontall mt-5">
        <v-btn
          @click="login"
          elevation="0"
          :loading="bLoading"
          class="button-login mon-bold"
        >{{ texts.login.buttonLogin }}</v-btn>
      </div>
      <div class="mt-7">
        <v-btn
          to="/password-recovery"
          class="link-forgot-password mon-regular"
          text
        >
          {{ texts.login.forgotPassword }}
        </v-btn>
      </div>
      <div class="mt-5">
        <v-btn
          to="/signin"
          elevation="0"
          class="button-create-account mon-regular"
        >
          {{ texts.login.proplatAccount }} <span class="mon-bold ml-1">PROPLAT</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  name: "LoginFormLayout",
  data() {
    return {
      //VARIABLES
      email: "",
      password: "",
      bShowPassword: false,
      bLoading: false,
    };
  },
  methods: {
    home: function () {
      this.$router.push("/welcome").catch(() => {});
    },
    preLogin: function ($event) {
      if ($event.code == "Enter") this.login();
    },
    login: function () {
      this.bLoading = true;

      const payload = {
        sEmail: this.email,
        sPassword: this.password,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/auth/customer`, payload)
        .then((response) => {
          this.bLoading = false;
          this.$store.commit("setToken", response.data.sToken);
          // this.$store.commit("setEmail", response.data.sEmail);
          this.$store.commit("setFullName", response.data.sFullname);
          this.$store.commit("setEnterpriseId", response.data.sEnterpriseId);
          this.$store.commit("setUserId", response.data.sUserId);
          this.$store.commit("setIsLogged", true);
          this.$store.commit("setManager", response.data.bManager);

          this.mixInfo(response.data.sMessage);
          this.$router.push("/dashboard").catch(() => {});
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
};
</script>

<style>
.content-login .theme--light.v-label {
  color: #000 !important;
}
</style>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.button-create-account {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 12px;
  opacity: 1;
  background-color: #ffffff !important;
  text-transform: initial;
  font-size: 14px;
  width: 100%;
}

.link-forgot-password {
  text-transform: initial;
  color: #0049b7;
  text-decoration: underline;
  letter-spacing: 0px;
}

.button-login {
  width: 100%;
  height: 40px !important;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  text-transform: initial;
}

.text-title {
  font-size: 30px;
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
}

.container-form-login {
  width: 100%;
  height: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-horizontall {
  display: flex;
  justify-content: center;
}

.content-image {
  width: 260px;
  height: 45px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.content-login {
  width: 70%;
  margin-top: -13%;
}

@media (max-width: 720px) {
  .content-login {
    width: 90%;
  }

  .text-title {
    font-size: 25px;
  }
}
</style>